export const MAX_COUPON_STEPS_SHOW = 3

// 券包弹窗类型
export const COUPON_DIALOG_TYPE = {
  CouponPackage: 'couponPackage',
  CouponRemind: 'couponRemind',
}

// 券包埋点 act_id 类型
// act_id：区分
// 0-关闭
// 1-底部button（ok 或 collect all）
// 2-跳转券列表（check coupon）
// 3-选品链接
// 4-use按钮
// 5-膨胀券按钮点击
export const COUPON_BA_ACT_ID = {
  CLOSE: 0,
  BOTTOM_BUTTON: 1,
  CHECK_COUPON: 2,
  PRODUCT_SELECT_LINK: 3,
  USE_BUTTON: 4,
  EXPANSION_COUPON_BUTTON: 5,
  BOOST_BUTTON: 7,
  CLAIM_BUTTON: 9,
}

export const MANUAL_CLAIM_COUPON_STEP = {
  UNSET: 0,
  PENDDING_CLAIM: 1,
  CLAIMED: 2,
}

export const COUPON_ANIMATION_URL = {
  gift: 'https://shein.ltwebstatic.com/appjson/2025/03/20/1742439757979369815.json',
  turntable: 'https://shein.ltwebstatic.com/appjson/2025/03/20/17424397581253832265.json',
  turntableHold: 'https://shein.ltwebstatic.com/appjson/2025/03/20/17424397601598755878.json',
  shopNowJump: 'https://shein.ltwebstatic.com/appjson/2025/03/20/17424397622965818535.json',
  fakeBoost: 'https://shein.ltwebstatic.com/appjson/2025/03/20/1742439763327637702.json',
  fakeTwoCoupon: 'https://shein.ltwebstatic.com/appjson/2025/03/20/17424397633158650387.json',
  boostPartOne: 'https://shein.ltwebstatic.com/appjson/2025/03/20/17424397634203095060.json',
  boostPartTwo: 'https://shein.ltwebstatic.com/appjson/2025/03/20/1742439762311331427.json',
}
