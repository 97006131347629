<template>
  <ClientOnly>
    <slot></slot>
  </ClientOnly>
</template>

<script>
import { defineComponent } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'

export default defineComponent({
  name: 'ClientOnlySlot',
  components: {
    ClientOnly
  },
})
</script>
